import CoBrandingBannerDesktop_ResponsiveComponent from '@wix/thunderbolt-elements/src/components/FreemiumBanner/CoBrandingBannerDesktop/viewer/skinComps/Responsive/Responsive.skin';


const CoBrandingBannerDesktop_Responsive = {
  component: CoBrandingBannerDesktop_ResponsiveComponent
};


export const components = {
  ['CoBrandingBannerDesktop_Responsive']: CoBrandingBannerDesktop_Responsive
};

